<template>
  <div class="markets-detail-page common-detail-page">
    <Nav />
    <div class="top-img">
      <img :src="detail.cover_image" alt="" />
    </div>
    <div class="common-card-wrap card-wrap-top">
      <div class="about">
        <h3>{{ detail.name }}</h3>
        <p class="title-tip">{{ detail.summary }}</p>
        <svg
          class="svg-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
            fill="#CDD7DE"
          />
        </svg>
        <div class="title-detail" v-html="detail.detail"></div>
      </div>
    </div>
    <div
      class="common-card-wrap24 card-wrap-product"
      v-if="
        Object.keys(detail.tabs || {}).length ||
        (detail?.productList && detail.productList.length)
      "
    >
      <div class="custom-vant-tab tab-wrap">
        <van-tabs type="card" v-model="activeName" swipe-threshold="3">
          <van-tab title="产品列表" v-if="detail?.productList && detail.productList.length">
            <div class="product-wrap">
              <div class="product"
                v-for="(item, index) in detail?.productList"
                @click="goProductDetail(item.uuid)"
                :key="index"
              >
                <div class="left">
                  <img :src="item.image" alt="" />
                </div>
                <div class="cont">
                  <div class="tag">{{ item.tags.join(' ') }}</div>
                  <div class="title ellipsis-two">{{ item.title }}</div>
                  <div class="model ellipsis-one">{{ item.sub_title }}</div>
                  <div class="btn-text">了解更多</div>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab
            :title="tab"
            v-for="tab in Object.keys(detail.tabs || {})"
            :key="tab"
          >
            <van-swipe
              class="video-swipe info-swipe"
              indicator-color="#FC0"
              v-if="tab === '视频'"
            >
              <van-swipe-item
                v-for="(item, index) in detail.tabs[tab].list"
                :key="index"
              >
                <div class="circle-box">
                  <video :src="item.download_link" controls />
                  <div class="title-box">
                    {{ item.name }}
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
            <div class="product-wrap" v-else-if="detail.tabs[tab].type == 3">
              <div class="product"
                v-for="(item, index) in detail.tabs[tab].list"
                @click="goProductDetail(item.uuid)"
                :key="index"
              >
                <div class="left">
                  <img :src="item.image" alt="" />
                </div>
                <div class="cont">
                  <div class="tag">{{ item.tags.join(' ') }}</div>
                  <div class="title ellipsis-two">{{ item.title }}</div>
                  <div class="model ellipsis-one">{{ item.sub_title }}</div>
                  <div class="btn-text">了解更多</div>
                </div>
              </div>
            </div>
            <div class="doc-list" v-else-if="detail.tabs[tab]">
              <div
                v-for="(item, index) in detail.tabs[tab].list"
                :key="index"
                class="doc"
              >
                <div class="pdf-icon"></div>
                <div class="doc-name">{{ item.name }}</div>
                <div class="down-icon">
                  <a :href="item.download_link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.39961 12.4319V9.2001H10.5996V12.4399L11.9832 11.0561L12.8316 11.9046L10.5693 14.1673C10.2568 14.4799 9.75028 14.4799 9.43788 14.1674L7.17561 11.9046L8.02401 11.0561L9.39961 12.4319ZM10.5996 9.2001H15.6004C16.0403 9.2001 16.3996 9.55677 16.3996 9.99675V15.6035C16.3996 16.041 16.0429 16.4001 15.603 16.4001H4.39626C3.95875 16.4001 3.59961 16.0434 3.59961 15.6035V9.99675C3.59961 9.55924 3.95741 9.2001 4.39877 9.2001H9.39961V3.6001H10.5996V9.2001Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="common-card-wrap24 card-wrap-scheme" v-if="activeName === 0">
      <h1>定制解决方案</h1>
      <svg
        class="arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
          fill="#CDD7DE"
        />
      </svg>
      <div class="scheme-list">
        <div
          v-for="(item, index) in detail.customized_service || []"
          :key="index"
          class="scheme"
        >
          <h2>{{ item.title }}</h2>
          <div class="card-box">
            <img :src="item.cover_image" alt="" />
            <div class="about">
              <h3 class="b-title">{{ item.name }}</h3>
              <p class="b-text">{{ item.summary }}</p>
              <div class="btn-wrap">
                <!-- <router-link :to="item.link"> -->
                <div class="btn-text" @click="goDetail(item.uuid)">
                  了解更多
                </div>
                <!-- </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty"></div>
    </div>
  </div>
</template>
<script>
import { Tab, Tabs, Swipe, SwipeItem } from "vant";
import request from "../../utils/request";
import dayjs from "dayjs";
import Nav from "../../component/nav.vue";
export default {
  name: "MarketsDetail",
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Nav,
  },
  data() {
    return {
      activeName: 0,
      detail: {},
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY.MM.DD HH:mm");
    },
    // 获取新闻列表数据
    fetchInfo(id) {
      request
        .get("/industry_market/full_info/" + id) // 假设接口地址为 /news
        .then((response) => {
          let product = response.tabs["产品列表"].list
            ? [...response.tabs["产品列表"].list]
            : [];
          if (response.tabs["产品列表"]) delete response.tabs["产品列表"];
         
          this.detail = {
            ...response,
            productList: product,
          }; // 将接口返回的数据赋值给newsList
          console.log("🚀 ~ fetchInfo ~ this.detail:", this.detail);
          document.title = this.detail.name;
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    goDetail(id) {
      this.$router.push({ name: "customDetail", query: { id: id } });
    },
    goProductDetail(id){
      this.$router.push({ name: "productDetail", query: { id: id } });
    }
  },
  created() {
    const id = this.$route.query.id;
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchInfo(id);
  },
};
</script>
<style lang="less" scoped>
.markets-detail-page {
  .about {
    h3 {
      color: #212027;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 33.6px */
    }
    .title-tip {
      margin-bottom: 10px;
      color: #505054;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
    }
    .svg-icon{
      margin-bottom: 20px;
    }
    .title-detail{
      color: #505054;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
    }
  }
  .card-wrap-top {
    padding-bottom: 120px;
    min-height: 160px;
  }
  .card-wrap-product {
    position: relative;
    top: -40px;
    padding-bottom: 88px;
    min-height: calc(100vh - 520px);
    .product-wrap {
      padding-top: 40px;
      .product {
        margin-bottom: 24px;
        display: flex;
        width: 100%;
        // height: 148px;
        border-radius: 24px;
        box-shadow: 0px 0px 16px 0px #d7d9dc;
        overflow: hidden;
        img {
          display: block;
          width: 120px;
          height: 100%;
          object-fit: cover;
        }
        .cont {
          padding: 12px;
          .tag {
            margin-bottom: 8px;
            color: #fabf00;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
          }
          .title {
            margin-bottom: 8px;
            color: #212027;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 21.6px */
          }
          .model {
            margin-bottom: 14px;
            overflow: hidden;
            color: #a9a9ac;
            text-overflow: ellipsis;
            white-space: break-spaces;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%; /* 14px */
          }
          .btn-text {
            color: #fabf00;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 19.2px */
          }
        }
      }
    }
  }
  .card-wrap-scheme {
    position: relative;
    top: -80px;
    h1{
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 40px;
    }
    .arrow {
      margin-bottom: 32px;
    }
    .scheme-list {
      .card-box {
        margin-bottom: 24px;
        border-radius: 24px;
        border: 1px solid #fff;
        background: #fff;

        /* 卡片投影 */
        box-shadow: 0px 0px 16px 0px #d7d9dc;
        overflow: hidden;
      }
      h2 {
        margin-bottom: 24px;
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 28.8px */
      }
      .about {
        padding: 16px;
      }
      img {
        display: block;
        width: 100%;
        height: 214px;
        object-fit: cover;
      }
      .b-title {
        margin-bottom: 8px;
        color: #212027;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 21.6px */
      }
      .b-text {
        margin-bottom: 8px;
        color: #505054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
      .btn-wrap {
        display: flex;
        .btn-text {
          box-sizing: border-box;
          width: 160px;
          padding: 12px 24px;
          color: #212027;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
          border-radius: 40px;
          background: #fc0;
          text-align: center;
        }
      }
    }
  }
  .video-swipe {
    padding: 32px 0 70px 0;
    .circle-box {
      width: 327px;
      height: 246px;
      border-radius: 24px;
      overflow: hidden;
      background: #fff;
      box-shadow: 0px 0px 16px 0px #d7d9dc;
    }
    video {
      display: block;
      width: 327px;
      height: 184px;
      object-fit: cover;
    }
    .title-box {
      padding: 16px 16px 24px 16px;
      line-height: 22px;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .doc-list {
    padding: 32px 0 0px 0;
    .doc {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding: 16px;
      border-radius: 12px;
      border: 1px solid #fff;
      background: #fff;

      /* 卡片投影 */
      box-shadow: 0px 0px 16px 0px #d7d9dc;
      .pdf-icon {
        margin-right: 8px;
        width: 38px;
        height: 38px;
        background: url("../../image/pdf-icon.png") no-repeat center / cover;
      }
      .doc-name {
        color: #212027;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        flex: 1;
        line-height: 120%; /* 19.2px */
      }
      .down-icon {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background: @theme-color;
          border-radius: 50%;
        }
      }
    }
  }
  .empty {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
  }
}
</style>
.
<style lang="less">
.markets-detail-page {
  .card-wrap-product {
    .van-tabs--card > .van-tabs__wrap {
      padding: 0;
      justify-content: center;
    }
  }
  .van-swipe__indicator {
    background: #777a7b;
  }
}
</style>
